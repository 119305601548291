@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./style/global.scss";


.react-body {
    .content {
        min-height: 100vh;
    }

    .width-50 {
        width: 49%;
        margin-left: 0.5%;
        margin-right: 0.5%;
    }

    h1, h2, h3, h4, h5, h6 {
        > a {
            color:inherit;
            font-size: inherit;
        }
    }
}