.react-body {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 32px;
    }
    
    h3 {
        font-size: 24px;
    }
    
    h4 {
        font-size: 24px;
    }
    
    h5{
        font-size: 18px;
    }
    
    h6 {
        font-size: 16px;
    }
    
    h1,h2,h3,h4,h5,h6 {
        margin-bottom: 10px;
        font-weight: bold;

        > a { 
            line-height: 1.2;
        }
    }


    .content {
        min-height: 100vh;
    }

    .flex  {
        div {
            padding-left: 8px;
            padding-right: 8px;
        }

        .w-40 {
            width: 40%;
        }

        .w-60 {
            width: 60%;
        }

        .w-50 {
            width: 50%;
        }
    }

    .mb-16 {
        margin-bottom: 16px;
    }

    .self-center {
        margin-left: auto;
        margin-right: auto;
    }
}
