.search-section {

    .search-box {
        margin-left:auto;
        margin-right: auto;
    }

    .search-box {
        width: 400px;
    }
}